export const CafeList = {
  cafe1: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Hatti Kappi</td>

        </tr>
        <tr className="head">
          <td>Item Description</td>
          <td>Gramage</td>
          <td>Rate</td>
        </tr>
        <tr>
          <td>KAAPI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">90</td>
        </tr>
        <tr>
          <td>BELLA KAAPI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>KAAPI LARGE </td>
          <td className="ft-Wt-700">250 ml</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>BELLA KAAPI LARGE </td>
          <td className="ft-Wt-700">250 ml</td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>CHAIS</td>

        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">90</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">90</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">90</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>OTHERS</td>

        </tr>
        <tr>
          <td>BADAM MILK</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>COLD BEVERAGES (200 Ml)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700">300 ml</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>COLD BADAM MILK</td>
          <td className="ft-Wt-700">300 ml</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>COLD LEMON CHAI</td>
          <td className="ft-Wt-700">300 ml</td>
          <td className="ft-Wt-700">140</td>
        </tr>
        <tr>
          <td>TENDER COCONUT WITH LIME N MINT</td>
          <td className="ft-Wt-700">200 ml</td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr>
          <td>CHOCOLATE COLD COFFEE</td>
          <td className="ft-Wt-700">300 ml</td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>ALOO MASALA SAMOSA (2Pcs)</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>JAIN SAMOSA (2 Pcs)</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr>
          <td>PUNJABI SAMOSA(2 Pcs)</td>
          <td className="ft-Wt-700">150gms</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>SABUDANA VADA(2 Pcs)</td>
          <td className="ft-Wt-700">100gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>MASALA CORN SAMOSA (2Pcs)</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>VEG SPICY SAMOSA (2 Pcs)</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>BANANA CAKE (1 pc)</td>
          <td className="ft-Wt-700">125 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>KAAPI BROWNIE (1 pc)</td>
          <td className="ft-Wt-700">100 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>KOREAN BUNS (1pc)</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>SPINACH &amp; CORN SANDWICH</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>BLUEBERRY MUFFINS</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>BOMBAY CHUTNEY SANDWICH </td>
          <td className="ft-Wt-700">200 gms</td>
          <td className="ft-Wt-700">140</td>
        </tr>
        <tr>
          <td>MIX VEG WRAP</td>
          <td className="ft-Wt-700">200 gms</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>BUN MASKA</td>
          <td className="ft-Wt-700">120 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>PASTRIES</td>
          <td className="ft-Wt-700">125 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>CHOCOLAVA CAKE</td>
          <td className="ft-Wt-700">100 gms</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>VANILLA MUFFINS</td>
          <td className="ft-Wt-700">80 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>CHOCO CHIP MUFFIN</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>PEPPER MUSHROOM </td>
          <td className="ft-Wt-700">200 gms</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>PIZZA POCKETS</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr>
          <td>ACHARI TOFU WRAP</td>
          <td className="ft-Wt-700">200 gms</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>6 SECONDS MERCHANDISE</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION(pack of 1)</td>
          <td></td>
          <td className="ft-Wt-700">75</td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION(pack of 3)</td>
          <td></td>
          <td className="ft-Wt-700">225</td>
        </tr>
        <tr>
          <td>6 SEONDS HATTI KASHAYA(pack of 1)</td>
          <td></td>
          <td className="ft-Wt-700">75</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI KASHAYA (pack of 3)</td>
          <td></td>
          <td className="ft-Wt-700">225</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI GOLDEN LATTE (pack of 1)</td>
          <td></td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI GOLDEN LATTE (pack of 3)</td>
          <td></td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr>
          <td>KAAPI POWDER TUBE (250 GM)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>CHICORY BLEND (80:20) </td>
          <td></td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>MYSORE KAAPI</td>
          <td></td>
          <td className="ft-Wt-700">190</td>
        </tr>
        <tr>
          <td>PURE KAAPI</td>
          <td></td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>KAAPI POWDER TIN (200 GM)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>MALNAD DAYS</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr>
          <td>SUTRA</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr>
          <td>MONSOON MALABAR</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr>
          <td>MYSORE NUGGETS</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr>
          <td>JAGGERY POWDER (250 GM)</td>
          <td></td>
          <td className="ft-Wt-700">75</td>
        </tr>
        <tr>
          <td>INDIANO(ROASTED BEANS)</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FILTERS</td>

        </tr>
        <tr>
          <td>S S KAAPI FILTER</td>
          <td></td>
          <td className="ft-Wt-700">550</td>
        </tr>
        <tr>
          <td>S S KAAPI KETTLE</td>
          <td></td>
          <td className="ft-Wt-700">800</td>
        </tr>
        <tr>
          <td>HAMMERED BRASS CUP N DABRA</td>
          <td></td>
          <td className="ft-Wt-700">1555</td>
        </tr>
        <tr>
          <td>HAMMERED BRASS FILTER</td>
          <td></td>
          <td className="ft-Wt-700">1830</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PACKED SNACKS</td>

        </tr>
        <tr>
          <td>KODUBALE</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>BOMBAY MIXTURE</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>COATED PEANUTS</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>MOONG DAL</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>KHARA BOONDHI</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>MADDUR VADA(pack of 5)</td>
          <td></td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>OATMEAL COOKIES(pack of 6)</td>
          <td></td>
          <td className="ft-Wt-700">140</td>
        </tr>
      </table>
    );
  },
  cafe2: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Highness panini</td>

        </tr>

        <tr className="head">
          <td>Particular</td>
          <td>gms/ml</td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SNACKS &amp; BAKERY</td>

        </tr>
        <tr>
          <td>Mexican (jain/regular)</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Paneer Tikka</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Cheese Melt Panini (jain/regular)</td>
          <td className="ft-Wt-700">160 gms</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Cheese Mayo Garlic Bread</td>
          <td className="ft-Wt-700">100 gms</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Veg Grilled Sandwich (jain/regular)</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Melted Sandwich (Jain/regular)</td>
          <td className="ft-Wt-700">160 gms</td>
          <td className="ft-Wt-700">280</td>
        </tr>
        <tr>
          <td>Maska Bun</td>
          <td className="ft-Wt-700">50 gms</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr>
          <td>Jam Bun</td>
          <td className="ft-Wt-700">50 gms</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA</td>

        </tr>
        <tr>
          <td>Margerita Pizza (jain/regular)</td>
          <td className="ft-Wt-700">140 gms </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Peppy Paneer Pizza (jain/regular)</td>
          <td className="ft-Wt-700">170 gms </td>
          <td className="ft-Wt-700">280</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>ROLL</td>

        </tr>
        <tr>
          <td>Paneer Tikka roll</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>

        </tr>
        <tr>
          <td>Regular adrak tea</td>
          <td className="ft-Wt-700">100 ml</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td>Regular Tea</td>
          <td className="ft-Wt-700">100 ml</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td>Masala Tea</td>
          <td className="ft-Wt-700">150 ml</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Black Tea</td>
          <td className="ft-Wt-700">100 ml</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td>Lemon Tea</td>
          <td className="ft-Wt-700">150 ml</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Green Tea</td>
          <td className="ft-Wt-700">150 ml</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700">150ML</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr>
          <td>CAFFE AMERICANO</td>
          <td className="ft-Wt-700">200ML</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>

        </tr>
        <tr>
          <td>Signature Mocha cold coffee </td>
          <td className="ft-Wt-700">300ML</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Signature cold coffee </td>
          <td className="ft-Wt-700">300ml</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Signature Iced Americano</td>
          <td className="ft-Wt-700">300ml</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Lemon ice tea</td>
          <td className="ft-Wt-700">300ml</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Peach Ice Tea</td>
          <td className="ft-Wt-700">300ml</td>
          <td className="ft-Wt-700">180</td>
        </tr>
      </table>
    );
  },
  cafe3: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Starbucks </td>
          <td></td>
          <td></td>
        </tr>

        <tr className="head">
          <td></td>
          <td>Ml / Grams</td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Hot Coffee</td>

        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Flat White</td>
          <td>237ml</td>
          <td>268</td>
        </tr>
        <tr>
          <td>Caramel Macchiato</td>
          <td>237ml</td>
          <td>320</td>
        </tr>
        <tr>
          <td>Café Mocha ( Without Cream )</td>
          <td>237ml</td>
          <td>299</td>
        </tr>
        <tr>
          <td>Café Latte</td>
          <td>237ml</td>
          <td>236</td>
        </tr>
        <tr>
          <td>Cappuccino </td>
          <td>237ml</td>
          <td>231</td>
        </tr>
        <tr>
          <td>Chocolate Cappuccino</td>
          <td>237ml</td>
          <td>288</td>
        </tr>
        <tr>
          <td>Café Americano</td>
          <td>237ml</td>
          <td>231</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Hot Tea</td>

        </tr>
        <tr>
          <td>Emperor&#39;s Cloud Mist ( Green Tea )</td>
          <td>237ml</td>
          <td>299</td>
        </tr>
        <tr>
          <td>Chai Tea Latte</td>
          <td>237ml</td>
          <td>268</td>
        </tr>
        <tr>
          <td>English Breakfast Tea ( Black Tea )</td>
          <td>237ml</td>
          <td>289</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Food</td>

        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Hawaiian Veg Club Sandwich</td>
          <td>185gm</td>
          <td>373</td>
        </tr>
        <tr>
          <td>Chilli Paneer In Herbed Bun</td>
          <td>180gm</td>
          <td>289</td>
        </tr>
        <tr>
          <td>Double Chocolate Chip Cookie</td>
          <td>80gm</td>
          <td>284</td>
        </tr>
        <tr>
          <td>Chatpata Paratha Wrap</td>
          <td>180gm</td>
          <td>347</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Creamy Spinach &amp; Corn Pocket</td>
          <td>160gm</td>
          <td>242</td>
        </tr>
        <tr>
          <td>Chocolate Truffle Pastry</td>
          <td>125gm</td>
          <td>373</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Frappuccino\Cold Coffee</td>

        </tr>
        <tr>
          <td>Javachip Frappuccino ( Without Cream )</td>
          <td>354ml</td>
          <td>310</td>
        </tr>
        <tr>
          <td>Caramel Frappuccino  ( Without Cream )</td>
          <td>354ml</td>
          <td>320</td>
        </tr>
        <tr>
          <td>Mocha Frappuccino  ( Without Cream )</td>
          <td>354ml</td>
          <td>331</td>
        </tr>
        <tr>
          <td>Ice White Mocha  ( Without Cream )</td>
          <td>354ml</td>
          <td>315</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Ready to Eat &amp; Drink</td>

        </tr>
        <tr>
          <td>Nuts</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Almond</td>
          <td>50m</td>
          <td>180</td>
        </tr>
        <tr>
          <td>Cashew</td>
          <td>50gm</td>
          <td>180</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Juices </td>

        </tr>
        <tr>
          <td>Rauch Organic Apple Juice</td>
          <td>200ml</td>
          <td>280</td>
        </tr>
        <tr>
          <td>Rauch Vitamin Drink Ace Juice</td>
          <td>200ml</td>
          <td>280</td>
        </tr>
      </table>
    );
  },
  cafe4: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Barista </td>

        </tr>

        <tr className="head">
          <td></td>
          <td></td>
          <td>Revised</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Esspresso Italiano (Small)</td>
          <td className="ft-Wt-700">140</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Espresso Italiano (Regular)</td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Americano (Small)</td>
          <td className="ft-Wt-700">160</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Americano (Regular)</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Latte (Small)</td>
          <td className="ft-Wt-700">210</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Latte (Regular)</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Cappuccino (Small)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Cappuccino (Regular)</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Mocha (Small)</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Mocha (Regular)</td>
          <td className="ft-Wt-700">260</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Hot Chocolate (Small)</td>
          <td className="ft-Wt-700">240</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Hot Chocolate (Regular)</td>
          <td className="ft-Wt-700">270</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Flat White (Small)</td>
          <td className="ft-Wt-700">220</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Flat White (Regular)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Blast(Small)</td>
          <td className="ft-Wt-700">320</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Blast(Regular)</td>
          <td className="ft-Wt-700">360</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Frappe(Small)</td>
          <td className="ft-Wt-700">270</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Frappe(Regular)</td>
          <td className="ft-Wt-700">310</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Chocolate Smoothie(Small)</td>
          <td className="ft-Wt-700">260</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Chocolate Smoothie(Regular)</td>
          <td className="ft-Wt-700">320</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Ginger Honey (Tea)</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista(Small)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista(Regular)</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Frappe (Small)</td>
          <td className="ft-Wt-700">310</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Americano(Small)</td>
          <td className="ft-Wt-700">270</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Americano(Regular) </td>
          <td className="ft-Wt-700">310</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Café Mocha (Small)</td>
          <td className="ft-Wt-700">240</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Café Mocha (Regular)</td>
          <td className="ft-Wt-700">280</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Latte(Small)</td>
          <td className="ft-Wt-700">220</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Latte(Regular)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Dark Temptation</td>
          <td className="ft-Wt-700">350</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Chocolate Excess</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Achari Paneer Roll </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Blueberry Muffin</td>
          <td className="ft-Wt-700">210</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Choco Chip Muffin</td>
          <td className="ft-Wt-700">210</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Brownie Fondente</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Marble Cake </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Wicked Brownie</td>
          <td className="ft-Wt-700">350</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Double Chocolate Chip Cookie</td>
          <td className="ft-Wt-700">125</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Chocolate Excess -Slice</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Vegetariano Puff</td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Three Pepper Cheese Toastie</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Oatmeal Raisin Cookie</td>
          <td className="ft-Wt-700">125</td>
        </tr>
      </table>
    );
  },
  cafe5: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Café Coffee Day</td>

        </tr>

        <tr className="head">
          <td></td>
          <td></td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Coffee</td>

        </tr>
        <tr>
          <td>Americano</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Cafe Latte</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Cappuccino</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Espresso</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Boost</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Horlicks</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Tea</td>

        </tr>
        <tr>
          <td>Cardamom Chai</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Garam Chai</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Ginger Chai</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Green Tea</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Lemon Chai</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Masala Chai</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Bottle Drinks</td>

        </tr>
        <tr>
          <td>Rush Pomegranate Apple</td>
          <td className="ft-Wt-700">200 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Rush Mango Passion</td>
          <td className="ft-Wt-700">330 Ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Storm Energy Drink</td>
          <td className="ft-Wt-700">330 Ml</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Classic Clod Coffee</td>
          <td className="ft-Wt-700">200 Ml</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr>
          <td>Hazelnut Clod Coffee</td>
          <td className="ft-Wt-700">200 Ml</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr>
          <td>Lemon Slush</td>
          <td className="ft-Wt-700">250 Ml</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr>
          <td>Coconut Water</td>
          <td className="ft-Wt-700">200 Ml</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Packed Producet</td>

        </tr>
        <tr>
          <td>Coffee Powder Degree</td>
          <td className="ft-Wt-700">200 Gm</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>Coffee Powder Dakshin</td>
          <td className="ft-Wt-700">200 Gm</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Cookies Double Chocochip</td>
          <td className="ft-Wt-700">25 G</td>
          <td className="ft-Wt-700">30</td>
        </tr>
        <tr>
          <td>Cookies Honey &amp; Oatmeal</td>
          <td className="ft-Wt-700">25 G</td>
          <td className="ft-Wt-700">30</td>
        </tr>
        <tr>
          <td>Banana Bites</td>
          <td className="ft-Wt-700">50 G</td>
          <td className="ft-Wt-700">60</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Food</td>

        </tr>
        <tr>
          <td>Savoury Samosa</td>
          <td className="ft-Wt-700">110 Grm</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Flaky Veg Puff</td>
          <td className="ft-Wt-700">110 Grm</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Spicy Vada Pav</td>
          <td className="ft-Wt-700">110 Grm</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Classic Garlic Bread</td>
          <td className="ft-Wt-700">110 Grm</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Tandoori Paneer Sw</td>
          <td className="ft-Wt-700">180 Grm</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Spinach N Corn Cheese Sw </td>
          <td className="ft-Wt-700">180 Grm</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Spicy Veg Burger</td>
          <td className="ft-Wt-700">180 Grm</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Banana Cake</td>
          <td className="ft-Wt-700">90 Grm</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Cocoa Fantasy Pastry</td>
          <td className="ft-Wt-700">90 Grm</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr className="subHead" >
          <td colSpan={3}>Water</td>

        </tr>
        <tr>
          <td>Water Bottle</td>
          <td className="ft-Wt-700">500 Ml </td>
          <td className="ft-Wt-700">20</td>
        </tr>
        <tr>
          <td>Soul Water</td>
          <td className="ft-Wt-700">500 Ml </td>
          <td className="ft-Wt-700">60</td>
        </tr>
      </table>
    );
  },
  cafe6: () => {
    return (
      <table>
        <tr>
          <td className="title">Lakhani IIJS TRITIYA 2024</td>

        </tr>

        <tr className="subHead">
          <td colSpan={3}>SNACKS</td>

        </tr>
        <tr>
          <td>Vada Pav </td>
          <td>50</td>
          <td> 100 gm  1 Pcs Pav, Powder Lassun Chutney., potato/kela stuffing</td>
        </tr>
        <tr>
          <td>Delhi Samosa (2pcs)</td>
          <td>50</td>
          <td>90 gm 1 Pcs Tomato Ketchup, potato &amp; green peas stuffing</td>
        </tr>
        <tr>
          <td>Grilled Cheese Sandwich</td>
          <td>250</td>
          <td>7&#39;&#39;*5&#39;&#39;*5 Tomato Ketchup, double layered  &amp; stuffed with cheese &amp; grilled</td>
        </tr>
        <tr>
          <td>Pavbhaji (2 Pav)</td>
          <td>260</td>
          <td>170 gm bhaji Chopped Onions &amp; Lime</td>
        </tr>
        <tr>
          <td>Extra Masala Pav (1pcs)</td>
          <td>30</td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SOUTH INDIAN</td>

        </tr>
        <tr>
          <td>Steam Idli (2pcs) Sambhar / Chutney</td>
          <td>140</td>
          <td> 75 gm each 3.5 inch approx Sambhar &amp; Chutney</td>
        </tr>
        <tr>
          <td>Sada Dosa</td>
          <td>170</td>
          <td> 14&#39;&#39;*12&#39;&#39; Sambhar &amp; Chutney, made in butter</td>
        </tr>
        <tr>
          <td>Masala Dosa</td>
          <td>220</td>
          <td> 14&#39;&#39;*12&#39;&#39; Sambhar &amp; Chutney, aloo masala made in butter</td>
        </tr>
        <tr>
          <td>Jini Dosa</td>
          <td>240</td>
          <td></td>
        </tr>
        <tr>
          <td>Onion Uttappa</td>
          <td>220</td>
          <td> 9&#39;&#39; Sambhar &amp; Chutney, made in butter</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>LEBENESE</td>

        </tr>
        <tr>
          <td>Falafel Roll</td>
          <td>280</td>
          <td>Size 7 inch, 2 Bullets, Pickled Veggie, Hummus, Tahini, Mayonnaise, Harissa Sauce.</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>ITALIAN</td>

        </tr>
        <tr>
          <td>Thin Crust Pizza with All Toppings (9 inch)</td>
          <td>450</td>
          <td></td>
        </tr>
        <tr>
          <td>Margherita Pizza</td>
          <td>400</td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COMBO MEALS</td>

        </tr>
        <tr>
          <td>Full Meals ( 2 Veg, 2 Roti, Dal, Rice,1 Gulab Jamun &amp; Pickle ) </td>
          <td>450</td>
          <td>100gm Vegetable, 2 roti&#39;s at 5.5&quot;, dal 200gm, rice 150 gm, 1 Pcs Gulab Jamun &amp; Pickle</td>
        </tr>
        <tr>
          <td>Chole Kulcha</td>
          <td>370</td>
          <td>150 gm chole 5.5&#39;&#39; kulcha served with amritsari chutney &amp; kachumber</td>
        </tr>
        <tr>
          <td>Extra Kulcha (1 Pc)</td>
          <td>90</td>
          <td></td>
        </tr>
        <tr>
          <td>Fried Rice with Manchurian</td>
          <td>300</td>
          <td>220 gm rice &amp; 150 ml gravy 4 balls of manchurian</td>
        </tr>
        <tr>
          <td>Biryani with Mix Veg Raita </td>
          <td>250</td>
          <td>200 gm with Raita</td>
        </tr>
        <tr>
          <td>Curd  Rice </td>
          <td>200</td>
          <td></td>
        </tr>
        <tr>
          <td>Rasam Rice </td>
          <td>200</td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FRESH JUICES</td>

        </tr>
        <tr>
          <td>Watermelon</td>
          <td>190</td>
          <td>220 ml </td>
        </tr>
        <tr>
          <td>Pineapple</td>
          <td>190</td>
          <td>220 ml </td>
        </tr>
        <tr>
          <td>Cocktail</td>
          <td>190</td>
          <td>220 ml </td>
        </tr>
        <tr>
          <td>Sweet Lime </td>
          <td>190</td>
          <td>220 ml </td>
        </tr>
        <tr>
          <td>Maramari</td>
          <td>190</td>
          <td>220 ml </td>
        </tr>
        <tr>
          <td>Sugar Cane Juice </td>
          <td>120</td>
          <td>220 ml </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>TEA/COFFEE</td>

        </tr>
        <tr>
          <td>Tea - Small</td>
          <td>50</td>
          <td>100 ml </td>
        </tr>
        <tr>
          <td>Coffee</td>
          <td>70</td>
          <td>130 ml </td>
        </tr>
        <tr>
          <td>Cold-Drinks (600ml Pet bottles)</td>
          <td>40</td>
          <td>500 ml</td>
        </tr>
        <tr>
          <td>Mineral Water</td>
          <td>25</td>
          <td>1 Liter</td>
        </tr>
        <tr>
          <td>VIP / Elite Café</td>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Snacks</td>

        </tr>
        <tr>
          <td>Bhel Puri </td>
          <td>160</td>
          <td></td>
        </tr>
        <tr>
          <td>Sev Puri</td>
          <td>160</td>
          <td></td>
        </tr>
        <tr>
          <td>Plain Vegetable Maggi</td>
          <td>140</td>
          <td></td>
        </tr>
        <tr>
          <td>Cheese Vegetable Maggi</td>
          <td>170</td>
          <td></td>
        </tr>
        <tr>
          <td>Khichiyu</td>
          <td>160</td>
          <td></td>
        </tr>
        <tr>
          <td>Beetroot Malawach Wrap</td>
          <td>280</td>
          <td></td>
        </tr>
        <tr>
          <td>Kesar Jalebi with Fafda</td>
          <td>220</td>
          <td></td>
        </tr>
        <tr>
          <td>Kesar Jalebi</td>
          <td>150</td>
          <td></td>
        </tr>
        <tr>
          <td>Lebanese Piza (6 inch)</td>
          <td>300</td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Beverages</td>

        </tr>
        <tr>
          <td>Watermelon </td>
          <td>190</td>
          <td></td>
        </tr>
        <tr>
          <td>Pineapple </td>
          <td>190</td>
          <td></td>
        </tr>
        <tr>
          <td>Cocktail</td>
          <td>190</td>
          <td></td>
        </tr>
        <tr>
          <td>Sweet Lime </td>
          <td>190</td>
          <td></td>
        </tr>
        <tr>
          <td>Maramari </td>
          <td>190</td>
          <td></td>
        </tr>
        <tr>
          <td>Tea - Small</td>
          <td>50</td>
          <td></td>
        </tr>
        <tr>
          <td>Cold-Drinks (200 ml Per bottles)</td>
          <td>40</td>
          <td></td>
        </tr>
        <tr>
          <td>Mineral Water (1 LTR ) </td>
          <td>25</td>
        </tr>
      </table>
    );
  },
  cafe7: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Starbucks </td>
          <td></td>
          <td></td>
        </tr>

        <tr className="head">
          <td></td>
          <td>Ml / Grams</td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Hot Coffee</td>

        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Flat White</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">268</td>
        </tr>
        <tr>
          <td>Caramel Macchiato</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">320</td>
        </tr>
        <tr>
          <td>Café Mocha ( Without Cream )</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">299</td>
        </tr>
        <tr>
          <td>Café Latte</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">236</td>
        </tr>
        <tr>
          <td>Cappuccino </td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">231</td>
        </tr>
        <tr>
          <td>Chocolate Cappuccino</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">288</td>
        </tr>
        <tr>
          <td>Café Americano</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">231</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Hot Tea</td>

        </tr>
        <tr>
          <td>Emperor&#39;s Cloud Mist ( Green Tea )</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">299</td>
        </tr>
        <tr>
          <td>Chai Tea Latte</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">268</td>
        </tr>
        <tr>
          <td>English Breakfast Tea ( Black Tea )</td>
          <td className="ft-Wt-700">237ml</td>
          <td className="ft-Wt-700">289</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Food</td>

        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Hawaiian Veg Club Sandwich</td>
          <td className="ft-Wt-700">185gm</td>
          <td className="ft-Wt-700">373</td>
        </tr>
        <tr>
          <td>Chilli Paneer In Herbed Bun</td>
          <td className="ft-Wt-700">180gm</td>
          <td className="ft-Wt-700">289</td>
        </tr>
        <tr>
          <td>Double Chocolate Chip Cookie</td>
          <td className="ft-Wt-700">80gm</td>
          <td className="ft-Wt-700">284</td>
        </tr>
        <tr>
          <td>Chatpata Paratha Wrap</td>
          <td className="ft-Wt-700">180gm</td>
          <td className="ft-Wt-700">347</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Creamy Spinach &amp; Corn Pocket</td>
          <td className="ft-Wt-700">160gm</td>
          <td className="ft-Wt-700">242</td>
        </tr>
        <tr>
          <td>Chocolate Truffle Pastry</td>
          <td className="ft-Wt-700">125gm</td>
          <td className="ft-Wt-700">373</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Frappuccino\Cold Coffee</td>

        </tr>
        <tr>
          <td>Javachip Frappuccino ( Without Cream )</td>
          <td className="ft-Wt-700">354ml</td>
          <td className="ft-Wt-700">310</td>
        </tr>
        <tr>
          <td>Caramel Frappuccino  ( Without Cream )</td>
          <td className="ft-Wt-700">354ml</td>
          <td className="ft-Wt-700">320</td>
        </tr>
        <tr>
          <td>Mocha Frappuccino  ( Without Cream )</td>
          <td className="ft-Wt-700">354ml</td>
          <td className="ft-Wt-700">331</td>
        </tr>
        <tr>
          <td>Ice White Mocha  ( Without Cream )</td>
          <td className="ft-Wt-700">354ml</td>
          <td className="ft-Wt-700">315</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Ready to Eat &amp; Drink</td>

        </tr>
        <tr>
          <td>Nuts</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Almond</td>
          <td className="ft-Wt-700">50m</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Cashew</td>
          <td className="ft-Wt-700">50gm</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Juices </td>

        </tr>
        <tr>
          <td>Rauch Organic Apple Juice</td>
          <td className="ft-Wt-700">200ml</td>
          <td className="ft-Wt-700">280</td>
        </tr>
        <tr>
          <td>Rauch Vitamin Drink Ace Juice</td>
          <td className="ft-Wt-700">200ml</td>
          <td className="ft-Wt-700">280</td>
        </tr>
      </table>
    );
  },
  cafe8: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Hatti Kappi</td>

        </tr>
        <tr className="head">
          <td>Item Description</td>
          <td>Gramage</td>
          <td>Rate</td>
        </tr>
        <tr>
          <td>KAAPI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">90</td>
        </tr>
        <tr>
          <td>BELLA KAAPI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>KAAPI LARGE </td>
          <td className="ft-Wt-700">250 ml</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>BELLA KAAPI LARGE </td>
          <td className="ft-Wt-700">250 ml</td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>CHAIS</td>

        </tr>
        <tr>
          <td>CHAI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">90</td>
        </tr>
        <tr>
          <td>GINGER CHAI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>LEMON CHAI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">90</td>
        </tr>
        <tr>
          <td>GREEN CHAI</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">90</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>OTHERS</td>

        </tr>
        <tr>
          <td>BADAM MILK</td>
          <td className="ft-Wt-700">180 ml</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>COLD BEVERAGES (200 Ml)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700">300 ml</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>COLD BADAM MILK</td>
          <td className="ft-Wt-700">300 ml</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>COLD LEMON CHAI</td>
          <td className="ft-Wt-700">300 ml</td>
          <td className="ft-Wt-700">140</td>
        </tr>
        <tr>
          <td>TENDER COCONUT WITH LIME N MINT</td>
          <td className="ft-Wt-700">200 ml</td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr>
          <td>CHOCOLATE COLD COFFEE</td>
          <td className="ft-Wt-700">300 ml</td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FOOD</td>
        </tr>
        <tr>
          <td>ALOO MASALA SAMOSA (2Pcs)</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>JAIN SAMOSA (2 Pcs)</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr>
          <td>PUNJABI SAMOSA(2 Pcs)</td>
          <td className="ft-Wt-700">150gms</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>SABUDANA VADA(2 Pcs)</td>
          <td className="ft-Wt-700">100gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>MASALA CORN SAMOSA (2Pcs)</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>VEG SPICY SAMOSA (2 Pcs)</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>BANANA CAKE (1 pc)</td>
          <td className="ft-Wt-700">125 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>KAAPI BROWNIE (1 pc)</td>
          <td className="ft-Wt-700">100 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>KOREAN BUNS (1pc)</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>SPINACH &amp; CORN SANDWICH</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>BLUEBERRY MUFFINS</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>BOMBAY CHUTNEY SANDWICH </td>
          <td className="ft-Wt-700">200 gms</td>
          <td className="ft-Wt-700">140</td>
        </tr>
        <tr>
          <td>MIX VEG WRAP</td>
          <td className="ft-Wt-700">200 gms</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>BUN MASKA</td>
          <td className="ft-Wt-700">120 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>PASTRIES</td>
          <td className="ft-Wt-700">125 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>CHOCOLAVA CAKE</td>
          <td className="ft-Wt-700">100 gms</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>VANILLA MUFFINS</td>
          <td className="ft-Wt-700">80 gms</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>CHOCO CHIP MUFFIN</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">110</td>
        </tr>
        <tr>
          <td>PEPPER MUSHROOM </td>
          <td className="ft-Wt-700">200 gms</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>PIZZA POCKETS</td>
          <td className="ft-Wt-700">90 gms</td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr>
          <td>ACHARI TOFU WRAP</td>
          <td className="ft-Wt-700">200 gms</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>6 SECONDS MERCHANDISE</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION(pack of 1)</td>
          <td></td>
          <td className="ft-Wt-700">75</td>
        </tr>
        <tr>
          <td>6 SECONDS FILTER COFFEE DECOCTION(pack of 3)</td>
          <td></td>
          <td className="ft-Wt-700">225</td>
        </tr>
        <tr>
          <td>6 SEONDS HATTI KASHAYA(pack of 1)</td>
          <td></td>
          <td className="ft-Wt-700">75</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI KASHAYA (pack of 3)</td>
          <td></td>
          <td className="ft-Wt-700">225</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI GOLDEN LATTE (pack of 1)</td>
          <td></td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>6 SECONDS HATTI GOLDEN LATTE (pack of 3)</td>
          <td></td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr>
          <td>KAAPI POWDER TUBE (250 GM)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>CHICORY BLEND (80:20) </td>
          <td></td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>MYSORE KAAPI</td>
          <td></td>
          <td className="ft-Wt-700">190</td>
        </tr>
        <tr>
          <td>PURE KAAPI</td>
          <td></td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>KAAPI POWDER TIN (200 GM)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>MALNAD DAYS</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr>
          <td>SUTRA</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr>
          <td>MONSOON MALABAR</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr>
          <td>MYSORE NUGGETS</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr>
          <td>JAGGERY POWDER (250 GM)</td>
          <td></td>
          <td className="ft-Wt-700">75</td>
        </tr>
        <tr>
          <td>INDIANO(ROASTED BEANS)</td>
          <td></td>
          <td className="ft-Wt-700">500</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>FILTERS</td>

        </tr>
        <tr>
          <td>S S KAAPI FILTER</td>
          <td></td>
          <td className="ft-Wt-700">550</td>
        </tr>
        <tr>
          <td>S S KAAPI KETTLE</td>
          <td></td>
          <td className="ft-Wt-700">800</td>
        </tr>
        <tr>
          <td>HAMMERED BRASS CUP N DABRA</td>
          <td></td>
          <td className="ft-Wt-700">1555</td>
        </tr>
        <tr>
          <td>HAMMERED BRASS FILTER</td>
          <td></td>
          <td className="ft-Wt-700">1830</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PACKED SNACKS</td>

        </tr>
        <tr>
          <td>KODUBALE</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>BOMBAY MIXTURE</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>COATED PEANUTS</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>MOONG DAL</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>KHARA BOONDHI</td>
          <td></td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>MADDUR VADA(pack of 5)</td>
          <td></td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>OATMEAL COOKIES(pack of 6)</td>
          <td></td>
          <td className="ft-Wt-700">140</td>
        </tr>
      </table>
    );
  },
  cafe9: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Highness panini</td>

        </tr>

        <tr className="head">
          <td>Particular</td>
          <td>gms/ml</td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SNACKS &amp; BAKERY</td>

        </tr>
        <tr>
          <td>Mexican (jain/regular)</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Paneer Tikka</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Cheese Melt Panini (jain/regular)</td>
          <td className="ft-Wt-700">160 gms</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Cheese Mayo Garlic Bread</td>
          <td className="ft-Wt-700">100 gms</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Veg Grilled Sandwich (jain/regular)</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Melted Sandwich (Jain/regular)</td>
          <td className="ft-Wt-700">160 gms</td>
          <td className="ft-Wt-700">280</td>
        </tr>
        <tr>
          <td>Maska Bun</td>
          <td className="ft-Wt-700">50 gms</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr>
          <td>Jam Bun</td>
          <td className="ft-Wt-700">50 gms</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA</td>

        </tr>
        <tr>
          <td>Margerita Pizza (jain/regular)</td>
          <td className="ft-Wt-700">140 gms </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Peppy Paneer Pizza (jain/regular)</td>
          <td className="ft-Wt-700">170 gms </td>
          <td className="ft-Wt-700">280</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>ROLL</td>

        </tr>
        <tr>
          <td>Paneer Tikka roll</td>
          <td className="ft-Wt-700">150 gms</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>

        </tr>
        <tr>
          <td>Regular adrak tea</td>
          <td className="ft-Wt-700">100 ml</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td>Regular Tea</td>
          <td className="ft-Wt-700">100 ml</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td>Masala Tea</td>
          <td className="ft-Wt-700">150 ml</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Black Tea</td>
          <td className="ft-Wt-700">100 ml</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td>Lemon Tea</td>
          <td className="ft-Wt-700">150 ml</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Green Tea</td>
          <td className="ft-Wt-700">150 ml</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700">150ML</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr>
          <td>CAFFE AMERICANO</td>
          <td className="ft-Wt-700">200ML</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>

        </tr>
        <tr>
          <td>Signature Mocha cold coffee </td>
          <td className="ft-Wt-700">300ML</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Signature cold coffee </td>
          <td className="ft-Wt-700">300ml</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Signature Iced Americano</td>
          <td className="ft-Wt-700">300ml</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Lemon ice tea</td>
          <td className="ft-Wt-700">300ml</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Peach Ice Tea</td>
          <td className="ft-Wt-700">300ml</td>
          <td className="ft-Wt-700">180</td>
        </tr>
      </table>
    );
  },
  cafe10: () => {
    return (
      <table>
      <tr className="title">
        <td colSpan={3}>Café Coffee Day</td>

      </tr>

      <tr className="head">
        <td></td>
        <td></td>
        <td>Rate</td>
      </tr>
      <tr className="subHead">
        <td colSpan={3}>Coffee</td>

      </tr>
      <tr>
        <td>Americano</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">100</td>
      </tr>
      <tr>
        <td>Cafe Latte</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Cappuccino</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">100</td>
      </tr>
      <tr>
        <td>Espresso</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">100</td>
      </tr>
      <tr>
        <td>Boost</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Horlicks</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr className="subHead">
        <td colSpan={3}>Tea</td>

      </tr>
      <tr>
        <td>Cardamom Chai</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Garam Chai</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">100</td>
      </tr>
      <tr>
        <td>Ginger Chai</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Green Tea</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Lemon Chai</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Masala Chai</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr className="subHead">
        <td colSpan={3}>Bottle Drinks</td>

      </tr>
      <tr>
        <td>Rush Pomegranate Apple</td>
        <td className="ft-Wt-700">200 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Rush Mango Passion</td>
        <td className="ft-Wt-700">330 Ml</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Storm Energy Drink</td>
        <td className="ft-Wt-700">330 Ml</td>
        <td className="ft-Wt-700">150</td>
      </tr>
      <tr>
        <td>Classic Clod Coffee</td>
        <td className="ft-Wt-700">200 Ml</td>
        <td className="ft-Wt-700">80</td>
      </tr>
      <tr>
        <td>Hazelnut Clod Coffee</td>
        <td className="ft-Wt-700">200 Ml</td>
        <td className="ft-Wt-700">80</td>
      </tr>
      <tr>
        <td>Lemon Slush</td>
        <td className="ft-Wt-700">250 Ml</td>
        <td className="ft-Wt-700">80</td>
      </tr>
      <tr>
        <td>Coconut Water</td>
        <td className="ft-Wt-700">200 Ml</td>
        <td className="ft-Wt-700">50</td>
      </tr>
      <tr className="subHead">
        <td colSpan={3}>Packed Producet</td>

      </tr>
      <tr>
        <td>Coffee Powder Degree</td>
        <td className="ft-Wt-700">200 Gm</td>
        <td className="ft-Wt-700">110</td>
      </tr>
      <tr>
        <td>Coffee Powder Dakshin</td>
        <td className="ft-Wt-700">200 Gm</td>
        <td className="ft-Wt-700">120</td>
      </tr>
      <tr>
        <td>Cookies Double Chocochip</td>
        <td className="ft-Wt-700">25 G</td>
        <td className="ft-Wt-700">30</td>
      </tr>
      <tr>
        <td>Cookies Honey &amp; Oatmeal</td>
        <td className="ft-Wt-700">25 G</td>
        <td className="ft-Wt-700">30</td>
      </tr>
      <tr>
        <td>Banana Bites</td>
        <td className="ft-Wt-700">50 G</td>
        <td className="ft-Wt-700">60</td>
      </tr>
      <tr className="subHead">
        <td colSpan={3}>Food</td>

      </tr>
      <tr>
        <td>Savoury Samosa</td>
        <td className="ft-Wt-700">110 Grm</td>
        <td className="ft-Wt-700">70</td>
      </tr>
      <tr>
        <td>Flaky Veg Puff</td>
        <td className="ft-Wt-700">110 Grm</td>
        <td className="ft-Wt-700">70</td>
      </tr>
      <tr>
        <td>Spicy Vada Pav</td>
        <td className="ft-Wt-700">110 Grm</td>
        <td className="ft-Wt-700">70</td>
      </tr>
      <tr>
        <td>Classic Garlic Bread</td>
        <td className="ft-Wt-700">110 Grm</td>
        <td className="ft-Wt-700">70</td>
      </tr>
      <tr>
        <td>Tandoori Paneer Sw</td>
        <td className="ft-Wt-700">180 Grm</td>
        <td className="ft-Wt-700">150</td>
      </tr>
      <tr>
        <td>Spinach N Corn Cheese Sw </td>
        <td className="ft-Wt-700">180 Grm</td>
        <td className="ft-Wt-700">150</td>
      </tr>
      <tr>
        <td>Spicy Veg Burger</td>
        <td className="ft-Wt-700">180 Grm</td>
        <td className="ft-Wt-700">150</td>
      </tr>
      <tr>
        <td>Banana Cake</td>
        <td className="ft-Wt-700">90 Grm</td>
        <td className="ft-Wt-700">70</td>
      </tr>
      <tr>
        <td>Cocoa Fantasy Pastry</td>
        <td className="ft-Wt-700">90 Grm</td>
        <td className="ft-Wt-700">100</td>
      </tr>
      <tr className="subHead" >
        <td colSpan={3}>Water</td>

      </tr>
      <tr>
        <td>Water Bottle</td>
        <td className="ft-Wt-700">500 Ml </td>
        <td className="ft-Wt-700">20</td>
      </tr>
      <tr>
        <td>Soul Water</td>
        <td className="ft-Wt-700">500 Ml </td>
        <td className="ft-Wt-700">60</td>
      </tr>
    </table>
    );
  },
  cafe11: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Barista </td>

        </tr>

        <tr className="head">
          <td></td>
          <td></td>
          <td>Revised</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Esspresso Italiano (Small)</td>
          <td className="ft-Wt-700">140</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Espresso Italiano (Regular)</td>
          <td className="ft-Wt-700">170</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Americano (Small)</td>
          <td className="ft-Wt-700">160</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Americano (Regular)</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Latte (Small)</td>
          <td className="ft-Wt-700">210</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Latte (Regular)</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Cappuccino (Small)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Cappuccino (Regular)</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Mocha (Small)</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Café Mocha (Regular)</td>
          <td className="ft-Wt-700">260</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Hot Chocolate (Small)</td>
          <td className="ft-Wt-700">240</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Hot Chocolate (Regular)</td>
          <td className="ft-Wt-700">270</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Flat White (Small)</td>
          <td className="ft-Wt-700">220</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Flat White (Regular)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Blast(Small)</td>
          <td className="ft-Wt-700">320</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Blast(Regular)</td>
          <td className="ft-Wt-700">360</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Frappe(Small)</td>
          <td className="ft-Wt-700">270</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Frappe(Regular)</td>
          <td className="ft-Wt-700">310</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Chocolate Smoothie(Small)</td>
          <td className="ft-Wt-700">260</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Chocolate Smoothie(Regular)</td>
          <td className="ft-Wt-700">320</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Ginger Honey (Tea)</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista(Small)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista(Regular)</td>
          <td className="ft-Wt-700">230</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Barista Frappe (Small)</td>
          <td className="ft-Wt-700">310</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Americano(Small)</td>
          <td className="ft-Wt-700">270</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Americano(Regular) </td>
          <td className="ft-Wt-700">310</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Café Mocha (Small)</td>
          <td className="ft-Wt-700">240</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Café Mocha (Regular)</td>
          <td className="ft-Wt-700">280</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Latte(Small)</td>
          <td className="ft-Wt-700">220</td>
        </tr>
        <tr>
          <td>Beverages</td>
          <td>Iced Latte(Regular)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Dark Temptation</td>
          <td className="ft-Wt-700">350</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Chocolate Excess</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Achari Paneer Roll </td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Blueberry Muffin</td>
          <td className="ft-Wt-700">210</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Choco Chip Muffin</td>
          <td className="ft-Wt-700">210</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Brownie Fondente</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Marble Cake </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Wicked Brownie</td>
          <td className="ft-Wt-700">350</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Double Chocolate Chip Cookie</td>
          <td className="ft-Wt-700">125</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Chocolate Excess -Slice</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Vegetariano Puff</td>
          <td className="ft-Wt-700">130</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Three Pepper Cheese Toastie</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Food</td>
          <td>Oatmeal Raisin Cookie</td>
          <td className="ft-Wt-700">125</td>
        </tr>
      </table>
    );
  },
  cafe12: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>TEA POST</td>
        </tr>
        <tr className="head">
          <td>TEA</td>
          <td> Price </td>
          <td></td>
        </tr>
        <tr>
          <td>Masala Tea</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Ginger Tea</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Elaichi Tea </td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Gud wali chai</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Kulhad Chai</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Saffron Tea</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>HOT BOURNVITA</td>
          <td className="ft-Wt-700">120</td>
          <td className="ft-Wt-700">180</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>COFFEE</td>
        </tr>
        <tr>
          <td>Hot Coffee</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Black Coffee</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">120</td>
        </tr>
        <tr>
          <td>Cold Coffee</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">300</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>ICE TEA</td>
        </tr>
        <tr>
          <td>lemon Ice Tea</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr>
          <td>Peach Ice Tea</td>
          <td className="ft-Wt-700">150</td>
          <td className="ft-Wt-700">300</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>TEA WITHOUT MILK</td>
        </tr>
        <tr>
          <td>Green Tea</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Black Tea</td>
          <td className="ft-Wt-700">70</td>
          <td className="ft-Wt-700">180</td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>NASHTA</td>
        </tr>
        <tr>
          <td>Veg Puff</td>
          <td className="ft-Wt-700">60</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Thepla (J)</td>
          <td className="ft-Wt-700">100</td>
          <td className="ft-Wt-700">80</td>
        </tr>
        <tr>
          <td>Maska Bun (J)</td>
          <td className="ft-Wt-700">80</td>
          <td className="ft-Wt-700">120</td>
        </tr>
      </table>
    );
  },
  cafe13: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>CHARCOAL EATS</td>
        </tr>
        <tr className="head">
          <td>FOOD MENU</td>
          <td> Price </td>
          <td> GM / ML </td>
        </tr>

        <tr>
          <td>HYDERABADI VEG BIRYANI</td>
          <td className="ft-Wt-700"> 250.00 </td>
          <td className="ft-Wt-700">350 GMS</td>
        </tr>
        <tr>
          <td>AWADHI BIRYANI</td>
          <td className="ft-Wt-700"> 250.00 </td>
          <td className="ft-Wt-700">350 GMS</td>
        </tr>
        <tr>
          <td>CHARCOAL VEG BIRYANI</td>
          <td className="ft-Wt-700"> 250.00 </td>
          <td className="ft-Wt-700">350 GMS</td>
        </tr>
        <tr>
          <td>PANEER BHUNA ROLL</td>
          <td className="ft-Wt-700"> 200.00 </td>
          <td className="ft-Wt-700"> 200 GMS </td>
        </tr>
        <tr>
          <td>PANEER TIKKA ROLL</td>
          <td className="ft-Wt-700"> 200.00 </td>
          <td className="ft-Wt-700">200 GMS</td>
        </tr>
      </table>
    );
  },
  cafe14: () => {
    return (
      <>
        <table>
          <tr className="title">
            <td colSpan={3}>QUICK BITES/QUENCH</td>
          </tr>
          <tr className="head">
            <td>Menu</td>
            <td> MRP</td>
            <td>GM / ML</td>
          </tr>
          <tr>
            <td>MASALA TEA</td>
            <td className="ft-Wt-700">      60.00  </td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>COFFEE</td>
            <td className="ft-Wt-700">      60.00  </td>
            <td className="ft-Wt-700">120 ML</td>
          </tr>
          <tr>
            <td>WATER</td>
            <td className="ft-Wt-700">      20.00  </td>
            <td className="ft-Wt-700">1000 ML</td>
          </tr>
          <tr>
            <td>SOFT DRINK - COKE</td>
            <td className="ft-Wt-700">      70.00  </td>
            <td className="ft-Wt-700">330 ML</td>
          </tr>
          <tr>
            <td>SOFT DRINK - THUMS UP</td>
            <td className="ft-Wt-700">      70.00  </td>
            <td className="ft-Wt-700">330 ML</td>
          </tr>
          <tr>
            <td>NATURE DAY (MANGO, LITCHI, GUAVA)</td>
            <td className="ft-Wt-700">      30.00  </td>
            <td className="ft-Wt-700">500 ML</td>
          </tr>
          <tr>
            <td>MM ORANGE</td>
            <td className="ft-Wt-700">      25.00  </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>MAAZA</td>
            <td className="ft-Wt-700">      20.00  </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>SPECIAL COFFEE</td>
            <td className="ft-Wt-700">   160.00  </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>SANDWICHES</td>
            <td className="ft-Wt-700">   180.00  </td>
            <td className="ft-Wt-700">200 G</td>
          </tr>
          <tr>
            <td>GR. SANDWICHES</td>
            <td className="ft-Wt-700">   220.00  </td>
            <td className="ft-Wt-700">220 G</td>
          </tr>
          <tr>
            <td>STROMBOLI PANEER</td>
            <td className="ft-Wt-700">      90.00  </td>
            <td className="ft-Wt-700">90 G</td>
          </tr>
          <tr>
            <td>CROISSANT PESTO CHEESE</td>
            <td className="ft-Wt-700">      90.00  </td>
            <td className="ft-Wt-700">60 G</td>
          </tr>
          <tr>
            <td>CROISSANT CHOCOLATE</td>
            <td className="ft-Wt-700">      90.00  </td>
            <td className="ft-Wt-700">60 G</td>
          </tr>
          <tr>
            <td>VEG PUFF</td>
            <td className="ft-Wt-700">      60.00  </td>
            <td className="ft-Wt-700">75 G</td>
          </tr>
          <tr>
            <td>SAMOSA</td>
            <td className="ft-Wt-700">      80.00  </td>
            <td className="ft-Wt-700">2 NOS</td>
          </tr>
        </table>

        <table>
          <tr className="title">
            <td colSpan={3}>TIBBS</td>
          </tr>
          <tr className="head">
            <td>Menu</td>
            <td> MRP</td>
            <td>GM / ML</td>
          </tr>
          <tr>
            <td>VEG COMBO 1 ( FRNK + BEVERAGE )</td>
            <td className="ft-Wt-700"> 200.00 </td>
            <td className="ft-Wt-700">200 GMS+ 250 ML</td>
          </tr>
          <tr>
            <td>BEVERAGE + BROWNIE</td>
            <td className="ft-Wt-700"> 150.00 </td>
            <td className="ft-Wt-700">250 ML + 80 GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>FRANKIE</td>
          </tr>
          <tr>
            <td>VEG FRANKIE</td>
            <td className="ft-Wt-700"> 160.00 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>BIRYANIS</td>
          </tr>
          <tr>
            <td>VEG BIRYANI </td>
            <td className="ft-Wt-700"> 230.00 </td>
            <td className="ft-Wt-700">600 GMS</td>
          </tr>
          <tr className="subHead">
            <td colSpan={3}>VEG FRANKIE </td>
          </tr>
          <tr>
            <td>CLASSIC VEG</td>
            <td className="ft-Wt-700"> 160.00 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>VEG SEZ</td>
            <td className="ft-Wt-700"> 160.00 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>VEG CHIPOTLE</td>
            <td className="ft-Wt-700"> 160.00 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>VEG CHEESE JELAPENO</td>
            <td className="ft-Wt-700"> 160.00 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>TAWA PANEER</td>
            <td className="ft-Wt-700"> 160.00 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>PANEER CHILLY</td>
            <td className="ft-Wt-700"> 160.00 </td>
            <td className="ft-Wt-700">200 GMS</td>
          </tr>
          <tr>
            <td>EXTRA CHEESE</td>
            <td className="ft-Wt-700"> 50.00 </td>
            <td className="ft-Wt-700">20 GMS</td>
          </tr>
          <tr>
            <td>CHIPOTLE SOUCE</td>
            <td className="ft-Wt-700"> 50.00 </td>
            <td className="ft-Wt-700">50 GMS</td>
          </tr>
          <tr>
            <td>CHEESE JELAPEENO SOUCE</td>
            <td className="ft-Wt-700"> 50.00 </td>
            <td className="ft-Wt-700">50 GMS</td>
          </tr>
          <tr>
            <td>SHIKANJI </td>
            <td className="ft-Wt-700"> 50.00 </td>
            <td className="ft-Wt-700">250 ML</td>
          </tr>
          <tr>
            <td>BROWNIE</td>
            <td className="ft-Wt-700"> 100.00 </td>
            <td className="ft-Wt-700">80 GMS</td>
          </tr>
          <tr>
            <td>NACHOS</td>
            <td className="ft-Wt-700"> 90.00 </td>
            <td className="ft-Wt-700">75 GMS</td>
          </tr>
        </table>
      </>
    );
  },
  cafe15: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>ZUO - SNACKS</td>
        </tr>

        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>BEVERAGES</td>
        </tr>
        <tr>
          <td>SOFT DRINK (330ML)</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER</td>
          <td className="ft-Wt-700"> 30.00 </td>
        </tr>
        <tr>
          <td>JASMIN TEA</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>MAINS</td>
        </tr>
        <tr>
          <td>FRAGRAENT FRIED RICE</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>BURNT GARLIC FRIED RICE</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>SHANGHAI HAKKA NOODLES / FRIED RICE</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES</td>
          <td className="ft-Wt-700"> 250.00 </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>APPETIZER</td>
        </tr>
        <tr>
          <td>DRY CHILLI COTTAGE CHEESE (PANEER)</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>CRISPY SPRING ROLL (2 ROLLS)</td>
          <td className="ft-Wt-700"> 150.00</td>
        </tr>
        <tr className="title">
          <td colSpan={3}>Quench &amp; QuickBites</td>
        </tr>

        <tr className="head">
          <td>PARTICULAR</td>
          <td> PRICES </td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>WATER</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>SOFT DRINK</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>NATURE DAY</td>
          <td className="ft-Wt-700"> 30.00 </td>
        </tr>
        <tr>
          <td>SPECIAL COFFEE</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>SANDWICHES</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>GR. SANDWICHES</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>STROMBOLI PANEER</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>CROISSANT PESTO CHEESE</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>CROISSANT CHOCOLATE</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>SAMOSA</td>
          <td className="ft-Wt-700"> 80.00</td>
        </tr>
      </table>
    );
  },
  cafe16: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>ZUO - SNACKS</td>
        </tr>

        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>BEVERAGES</td>
        </tr>
        <tr>
          <td>SOFT DRINK (330ML)</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER</td>
          <td className="ft-Wt-700"> 30.00 </td>
        </tr>
        <tr>
          <td>JASMIN TEA</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>MAINS</td>
        </tr>
        <tr>
          <td>FRAGRAENT FRIED RICE</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>BURNT GARLIC FRIED RICE</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>SHANGHAI HAKKA NOODLES / FRIED RICE</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES</td>
          <td className="ft-Wt-700"> 250.00 </td>
        </tr>

        <tr className="subHead">
          <td colSpan={3}>APPETIZER</td>
        </tr>
        <tr>
          <td>DRY CHILLI COTTAGE CHEESE (PANEER)</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>CRISPY SPRING ROLL (2 ROLLS)</td>
          <td className="ft-Wt-700"> 150.00</td>
        </tr>
        <tr className="title">
          <td colSpan={3}>Quench &amp; QuickBites</td>
        </tr>

        <tr className="head">
          <td>PARTICULAR</td>
          <td> PRICES </td>
        </tr>
        <tr>
          <td>MASALA TEA</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>WATER</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>SOFT DRINK</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>NATURE DAY</td>
          <td className="ft-Wt-700"> 30.00 </td>
        </tr>
        <tr>
          <td>SPECIAL COFFEE</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>SANDWICHES</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>GR. SANDWICHES</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>STROMBOLI PANEER</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>CROISSANT PESTO CHEESE</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>CROISSANT CHOCOLATE</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>SAMOSA</td>
          <td className="ft-Wt-700"> 80.00</td>
        </tr>
      </table>
    );
  },
  db3: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>ANANDO</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>SPECIAL MASALA MILK</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>BELGIAN CHOCOLATE</td>
          <td className="ft-Wt-700"> 135.00 </td>
        </tr>
        <tr>
          <td>TROPICAL COCONUT</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>SPLENDID STRAWBERRY</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>TANGO WITH MANGO</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>SINFUL SITAFAL</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>CHOCO ALMOND CRUNCHY</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>MAHARAJA BHOG ( 5 Dish Menu)</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>EXECUTIVE BOX</td>
          <td className="ft-Wt-700"> 300.00 </td>
        </tr>
        <tr>
          <td>PURI-5 PCS WITH BHAJI, ACHAR,SHRIKHAND</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>DAL BATTI CHURMA</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>VADA PAV</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>SAMOSA PAV </td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>MASALA CHASS</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>RING DHOKLA WITH COCONUT CHUTNEY</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>TEA</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>VEG BIRYANI WITH RAITA, PAPAD &amp; GULAB JAMUN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>THEPLA , BHAJI &amp; GULAB JAMUN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>PAV BHAJI</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>EXPRESS BOX</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>CHETTINAD EXPRESS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>IDLI (J)</td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>DOSA</td>
        </tr>
        <tr>
          <td>DOSA : SADA (J)</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>DOSA : MASALA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>DOSA : MYSORE MASALA</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>
        <tr>
          <td>DOSA : MYSORE SADA </td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>DOSA : BUTTER CHEESE MASALA</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>DOSA : BUTTER CHEESE (J)</td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>
        <tr>
          <td>DOSA : PAV BHAJI (J)</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>UTTAPPAM : ONION / TOMATO</td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>QUENCH</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COOL</td>
        </tr>
        <tr>
          <td>WATER (1 LIT) </td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>WATER (200 ML) - 1 CASE </td>
          <td className="ft-Wt-700"> 288.00 </td>
        </tr>
        <tr>
          <td>SOFT BEVERAGES</td>
          <td> MRP </td>
        </tr>
        <tr>
          <td>MINUTE MAID</td>
          <td> MRP </td>
        </tr>
        <tr>
          <td>NATURE DAY</td>
          <td> MRP </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>WARM</td>
        </tr>
        <tr>
          <td>TEA</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>R.S.MANI </td>
        </tr>
        <tr className="head">
          <td>NAME</td>
          <td> PRICE </td>
        </tr>
        <tr>
          <td> R S MANI FILTER COFFEE </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td> ADRAK CHAI </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td> IDLI </td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>
        <tr>
          <td> MEDU VADA </td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td> PINEAPPLE SHEERA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td> DOSAS </td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>QUICK BITES</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td> PRICE </td>
        </tr>
        <tr>
          <td>CHOLE KULCHA (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>
        <tr>
          <td>CHOLE BHATURE (J)</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>PAV BHAJI (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>
        <tr>
          <td>SAMOSA CHOLE (J)</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>MISAL PAV (J)</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>VADA PAV (J)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>SAMOSA (J)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>EXTRA PAV - (1PC)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>EXTRA KULCHA / BHATURA - (1PC)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>INDIC</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td> PRICE </td>
        </tr>
        <tr>
          <td>URBAN THALI. (J)</td>
          <td className="ft-Wt-700"> 270.00 </td>
        </tr>
        <tr>
          <td>BIRYANI : PANEER MAKHANI (J)</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td>BIRYANI : SUBZ (J)</td>
          <td className="ft-Wt-700"> 250.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>DAILY DELI</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SANDWICH</td>
        </tr>
        <tr>
          <td>GRILLED VEG SANDWICH (J)</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>GRILLED VEG SANDWICH WITH CHEESE (J)</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>WRAPS</td>
        </tr>
        <tr>
          <td>VEGETABLE WRAPS (J)</td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>CHEESE &amp; VEGETABLE WRAPS (J)</td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>
        <tr>
          <td>FRENCH FRIES</td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>PIZZA </td>
        </tr>
        <tr>
          <td>CHEESE MARGHERITA</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td>VEGGIE DELIGHT </td>
          <td className="ft-Wt-700"> 270.00 </td>
        </tr>
        <tr>
          <td>PEPPY PANEER </td>
          <td className="ft-Wt-700"> 280.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>CHINA TIMES</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td> PRICE </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}> CHINESE COMBOS </td>
        </tr>
        <tr>
          <td> FRIED RICE WITH MANCHURIAN (J) </td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td> NOODLES WITH MANCHURIAN (J) </td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td> VEG FRIED RICE </td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td> VEG NOODLES </td>
          <td className="ft-Wt-700"> 180.00</td>
        </tr>
      </table>
    );
  },
  openBay: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>SHIV SAGAR</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>PAV BHAJI</td>
          <td className="ft-Wt-700"> 200.00 </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>INDIC THALI</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>URBAN THALI- VEG</td>
          <td className="ft-Wt-700"> 270.00 </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>R.S.MANI </td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td> DOSA SADA </td>
          <td className="ft-Wt-700"> 100.00 </td>
          <td></td>
        </tr>
        <tr>
          <td> DOSA MASALA </td>
          <td className="ft-Wt-700"> 120.00 </td>
          <td></td>
        </tr>
        <tr>
          <td> BUTTER SADA DOSA </td>
          <td className="ft-Wt-700"> 120.00 </td>
          <td></td>
        </tr>
        <tr>
          <td> GHEE ROAST </td>
          <td className="ft-Wt-700"> 140.00 </td>
          <td></td>
        </tr>
        <tr>
          <td> MYSORE SADA DOSA </td>
          <td className="ft-Wt-700"> 120.00 </td>
          <td></td>
        </tr>
        <tr>
          <td> MYSORE MASALA DOSA </td>
          <td className="ft-Wt-700"> 140.00 </td>
          <td></td>
        </tr>
        <tr>
          <td> IDLI WADA PLATE</td>
          <td className="ft-Wt-700"> 100.00 </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>CHINA TIME</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>MEAL - VEG FRIED RICE</td>
          <td className="ft-Wt-700"> 180.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>Paneer Veg Chilly in Basil Sauce</td>
          <td className="ft-Wt-700"> 180.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>Veg Combo</td>
          <td className="ft-Wt-700"> 260.00 </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>QUICK BITES</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>CHOLE KULCHA</td>
          <td className="ft-Wt-700"> 150.00 </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>TIBBS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>VEG FRANKIE</td>
          <td className="ft-Wt-700"> 160.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>TAWA PANEER</td>
          <td className="ft-Wt-700"> 160.00 </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>MAHARAJA BHOG</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>VADA PAV </td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>SAMOSA PAV </td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>RAJDHANI THALI</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
          <td></td>
        </tr>
        <tr>
          <td>RAJDHANI EXECUTIVE THALI</td>
          <td className="ft-Wt-700"> 280.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>Samosa - 2 Pcs</td>
          <td className="ft-Wt-700"> 60 </td>
          <td></td>
        </tr>
        <tr>
          <td>(Samosa 2 Pcs + Chutney + Fried Chilli)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Khaman Dhokla </td>
          <td className="ft-Wt-700"> 100 </td>
          <td></td>
        </tr>
        <tr>
          <td>(Dhokla 5 Pcs + Chutney + Fried Chilli)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Samosa Chole Chat</td>
          <td className="ft-Wt-700"> 110 </td>
          <td></td>
        </tr>
        <tr>
          <td>(Samosa 2 Pc + Chole + Chutney)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Khasta Kachori Chat</td>
          <td className="ft-Wt-700"> 100 </td>
          <td></td>
        </tr>
        <tr>
          <td>(Kachori 1 Pc + Dahi + Chutney)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Misal Pav </td>
          <td className="ft-Wt-700"> 120 </td>
          <td></td>
        </tr>
        <tr>
          <td>(Misal + Pav 2 Pcs + Onion + Lemon)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Rajdhani Executive Thali</td>
          <td className="ft-Wt-700"> 280 </td>
          <td></td>
        </tr>
        <tr>
          <td>
            (Farsan + 2 Veg + 3 Chapati + Dal + Rice + 1 Sweet + Accompaniments)
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Paneer Combo Meal</td>
          <td className="ft-Wt-700"> 250 </td>
          <td></td>
        </tr>
        <tr>
          <td>
            (Paneer Veg + 3 Chapati + Dal + Rice + 1 Sweet + Accompaniments)
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Chole Puri </td>
          <td className="ft-Wt-700"> 180 </td>
          <td></td>
        </tr>
        <tr>
          <td>(Chole + Puri 5 Pcs + Accompaniments)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Puri Bhaji  </td>
          <td className="ft-Wt-700"> 150 </td>
          <td></td>
        </tr>
        <tr>
          <td>(Aloo Sabzi + Puri 5 Pcs +  Accompaniments)</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Masala Chaas</td>
          <td className="ft-Wt-700"> 60 </td>
          <td></td>
        </tr>
        <tr>
          <td>Maharaja Lassi</td>
          <td className="ft-Wt-700"> 100 </td>
          <td></td>
        </tr>
        <tr>
          <td>Extra Pav - 1 Pc</td>
          <td className="ft-Wt-700"> 10 </td>
          <td></td>
        </tr>
        <tr>
          <td>Gulab Jamun - 2 Pcs</td>
          <td className="ft-Wt-700"> 50 </td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>Society Tea Terminal</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
          <td></td>
        </tr>
        <tr>
          <td>REGULAR TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>MASALA TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>ELAICHI TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>ICED TEA</td>
          <td className="ft-Wt-700"> 80.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 100.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>KHARI </td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>TOAST </td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>BUN MASKA JAM (1 PC)</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>GREEN TEA BAGS</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>CLEANSE</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>HALDI DOODH</td>
          <td className="ft-Wt-700"> 60.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>POHA</td>
          <td className="ft-Wt-700"> 70.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>UPMA</td>
          <td className="ft-Wt-700"> 70.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>SANDWICH VEG</td>
          <td className="ft-Wt-700"> 100.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>CHEESE PUFF</td>
          <td className="ft-Wt-700"> 50.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>SAMOSA ( 1 PC)</td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>VADA </td>
          <td className="ft-Wt-700"> 40.00 </td>
          <td></td>
        </tr>
        <tr>
          <td>PAV</td>
          <td className="ft-Wt-700"> 10.00 </td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>BEVERAGE COUNTER</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP ( PER BTL) </td>
          <td> MRP (CASE) </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 1 LTR (12 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
          <td className="ft-Wt-700"> 240.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 250 ML (48 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
          <td className="ft-Wt-700"> 290.00 </td>
        </tr>
        <tr>
          <td>BISLERI LIMONATA 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
          <td className="ft-Wt-700"> 240.00 </td>
        </tr>
        <tr>
          <td>BISLERI POP - ORANGE 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
          <td className="ft-Wt-700"> 240.00 </td>
        </tr>
        <tr>
          <td>COKE OR THUMPS UP 250 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
          <td className="ft-Wt-700"> 480.00 </td>
        </tr>
        <tr>
          <td>LIMCA OR SLICE 250 ML ( 24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
          <td className="ft-Wt-700"> 480.00</td>
        </tr>
        <tr className="title">
          <td colSpan={3}>LOKAL</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>LOKAL VEG THALI</td>
          <td className="ft-Wt-700"> 280.00 </td>
        </tr>
        <tr>
          <td>LOKAL THEPLA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>JALEBI &amp; FAFDA</td>
          <td className="ft-Wt-700"> 135.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>SHIV SAGAR</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>INDIAN EXPRESS COMBO</td>
          <td className="ft-Wt-700"> 300.00 </td>
        </tr>
        <tr>
          <td>PAV BHAJI (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>CHETTINAD EXPRESS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>DOSA : SADA </td>
          <td className="ft-Wt-700"> 90.00 </td>
        </tr>
        <tr>
          <td>DOSA : MASALA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>DOSA : MYSORE MASALA</td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>
        <tr>
          <td>DOSA : MYSORE SADA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>IDLI WADA PLATE</td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>WOW MOMOS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>VEG DARJEELING STEAM MOMO (Steamed)</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>PANEER STEAM MOMO (Steamed)</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE STEAM MOMO (Steamed)</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>VEG DARJEELING FRIED MOMO (Fried)</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>PANEER FRIED MOMO (Fried)</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE FRIED MOMO (Fried)</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>VEG DARJEELING PAN FRIED MOMO</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>PANEER PAN FRIED MOMO</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE PAN FRIED MOMO</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td>CHIILLY PANEER DRY</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>FRIED RICE WITH CHILLI PANEER</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>FRIED RICE WITH VEG MANCHURIAN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES WITH CHILLI PANEER</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES WITH VEG MANCHURIAN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>COCO CHARGE</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>QUICK BITES</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>CHOLE KULCHA (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>
        <tr>
          <td>SAMOSA (J)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>VADA PAV (J)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>CHOLE KULCHA</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>Society Tea Terminal</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
        </tr>
        <tr>
          <td>REGULAR TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>MASALA TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>ELAICHI TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>ICED TEA</td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>KHARI 2PC</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>TOAST 2PC</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>BUN MASKA JAM (1 PC)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>GREEN TEA BAGS</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>CLEANSE</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>HALDI DOODH</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>POHA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>UPMA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>SANDWICH VEG</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>CHEESE PUFF</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>SAMOSA ( 1 PC)</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>VADA </td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>PAV</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>BEVERAGE COUNTER</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP ( PER BTL) </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 1 LTR (12 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 250 ML (48 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI LIMONATA 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI POP - ORANGE 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>COKE OR THUMPS UP 250 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>LIMCA OR SLICE 250 ML ( 24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>TIBBS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>VEG FRANKIE</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>TAWA PANEER</td>
          <td className="ft-Wt-700"> 160.00</td>
        </tr>
      </table>
    );
  },
  foodCourt: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>LOKAL</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>LOKAL VEG THALI</td>
          <td className="ft-Wt-700"> 280.00 </td>
        </tr>
        <tr>
          <td>LOKAL THEPLA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>JALEBI &amp; FAFDA</td>
          <td className="ft-Wt-700"> 135.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>SHIV SAGAR</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>PAVBHAJI </td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>SADA DOSA</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>MASALA DOSA</td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td>MYSORE SADA</td>
          <td className="ft-Wt-700"> 130.00 </td>
        </tr>
        <tr>
          <td>MYSORE MASALA </td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}> CHHOLAY</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>CHOLE KULCHA</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>QUENCH</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>COFFEE</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>VADA PAV </td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>SAMOSA </td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>WOW MOMOS</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>VEG DARJEELING STEAM MOMO (Steamed) 5pcs.</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>PANEER STEAM MOMO (Steamed) 5pcs.</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE STEAM MOMO (Steamed) 5pcs.</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>VEG DARJEELING FRIED MOMO (Fried) 5pcs.</td>
          <td className="ft-Wt-700"> 160.00 </td>
        </tr>
        <tr>
          <td>PANEER FRIED MOMO (Fried) 5pcs.</td>
          <td className="ft-Wt-700"> 190.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE FRIED MOMO (Fried) 5pcs.</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>VEG DARJEELING PAN FRIED MOMO 5pcs.</td>
          <td className="ft-Wt-700"> 210.00 </td>
        </tr>
        <tr>
          <td>PANEER PAN FRIED MOMO 5pcs</td>
          <td className="ft-Wt-700"> 220.00 </td>
        </tr>
        <tr>
          <td>CORN CHEESE PAN FRIED MOMO 5pcs.</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>
        <tr>
          <td>CHIILLY PANEER DRY</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>FRIED RICE WITH CHILLI PANEER</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>FRIED RICE WITH VEG MANCHURIAN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES WITH CHILLI PANEER</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>HAKKA NOODLES WITH VEG MANCHURIAN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr className="head">
          <td>COCO CHARGE</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>BEVERAGE COUNTER</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP ( PER BTL) </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 1 LTR (12 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 250 ML (48 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI LIMONATA 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI POP - ORANGE 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>COKE OR THUMPS UP 250 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>LIMCA OR SLICE 250 ML ( 24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>Society Tea Terminal</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP </td>
        </tr>
        <tr>
          <td>REGULAR TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>MASALA TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>ELAICHI TEA </td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>ICED TEA</td>
          <td className="ft-Wt-700"> 80.00 </td>
        </tr>
        <tr>
          <td>CAPPUCCINO</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>COLD COFFEE</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>KHARI 2PC</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>TOAST 2PC</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>BUN MASKA JAM (1 PC)</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>GREEN TEA BAGS</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>CLEANSE</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>HALDI DOODH</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>POHA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>UPMA</td>
          <td className="ft-Wt-700"> 70.00 </td>
        </tr>
        <tr>
          <td>SANDWICH VEG</td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td>VEG PUFF</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>CHEESE PUFF</td>
          <td className="ft-Wt-700"> 50.00 </td>
        </tr>
        <tr>
          <td>SAMOSA ( 1 PC)</td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>VADA </td>
          <td className="ft-Wt-700"> 40.00 </td>
        </tr>
        <tr>
          <td>PAV</td>
          <td className="ft-Wt-700"> 10.00</td>
        </tr>
        <tr className="title">
          <td colSpan={3}>MAHARAJA BHOG</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>EXECUTIVE BOX</td>
          <td className="ft-Wt-700"> 300.00 </td>
        </tr>
        <tr>
          <td>RING DHOKLA WITH COCONUT CHUTNEY</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>TEA</td>
          <td className="ft-Wt-700"> 60.00 </td>
        </tr>
        <tr>
          <td>VEG BIRYANI WITH RAITA, PAPAD &amp; GULAB JAMUN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>THEPLA , BHAJI &amp; GULAB JAMUN</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>PAV BHAJI</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>
        <tr>
          <td>EXPRESS BOX</td>
          <td className="ft-Wt-700"> 200.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>SHIV SAGAR</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>PAV BHAJI (J)</td>
          <td className="ft-Wt-700"> 150.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>R.S.MANI </td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td> DOSA SADA </td>
          <td className="ft-Wt-700"> 100.00 </td>
        </tr>
        <tr>
          <td> DOSA MASALA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td> BUTTER SADA DOSA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td> GHEE ROAST </td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>
        <tr>
          <td> MYSORE SADA DOSA </td>
          <td className="ft-Wt-700"> 120.00 </td>
        </tr>
        <tr>
          <td> MYSORE MASALA DOSA </td>
          <td className="ft-Wt-700"> 140.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>CHINA TIME</td>
        </tr>
        <tr className="head">
          <td>MENU</td>
          <td>PRICE</td>
        </tr>
        <tr>
          <td>MEAL - VEG FRIED RICE</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>Paneer Veg Chilly in Basil Sauce</td>
          <td className="ft-Wt-700"> 180.00 </td>
        </tr>
        <tr>
          <td>Veg Combo</td>
          <td className="ft-Wt-700"> 260.00 </td>
        </tr>

        <tr className="title">
          <td colSpan={3}>BEVERAGE COUNTER</td>
        </tr>
        <tr className="head">
          <td>Menu</td>
          <td> MRP ( PER BTL) </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 1 LTR (12 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>BISLERI MINERAL WATER 250 ML (48 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI LIMONATA 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>BISLERI POP - ORANGE 160 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 10.00 </td>
        </tr>
        <tr>
          <td>COKE OR THUMPS UP 250 ML (24 NOS)</td>
          <td className="ft-Wt-700"> 20.00 </td>
        </tr>
        <tr>
          <td>LIMCA OR SLICE 250 ML ( 24 NOS)</td>
          <td className="ft-Wt-700"> 20.00</td>
        </tr>
      </table>
    );
  },
  jioCafe1: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Grab &amp; Go</td>
        </tr>
        <tr className="head">
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COOKIES</td>
        </tr>
        <tr>
          <td>White chocolate &amp; cashew cookies </td>
        </tr>
        <tr>
          <td>(Contains Wheat, Nut, Milk, Soya)</td>
        </tr>
        <tr>
          <td>Chocolate chip cookies </td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk, Soya)</td>
          <td></td>
        </tr>
        <tr>
          <td>Any 2 Pieces</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Any 1 Piece</td>
          <td className="ft-Wt-700">40</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SAVORIES</td>
        </tr>
        <tr>
          <td>Cheese and Chutney Sandwich (Jain)</td>
        </tr>
        <tr>
          <td>(Conatins Wheat, Milk)</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Corn and Jalapeno Sandwich</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Choice of Grilled / Toast Sandwish</td>
          <td></td>
        </tr>
        <tr>
          <td>Samosa (2 Nos.) (Jain)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Masala Dal Kachori (2 Nos.)(Jain)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>Readymade Masala Tea</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Readymade Coffee</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Espresso</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Café Latte</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Cappuccino</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Americano</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>Anando Flavoured Milk (180 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>Anando Cold Coffee (200 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>Areated Beverage (330 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (200 ML X 24 nos.)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (500 ML)</td>
          <td className="ft-Wt-700">30</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (1000 ML)</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>New addition </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Prepackaged Treats </td>
        </tr>
        <tr>
          <td>Crisp wafer potatoes (35 Gms Lays) </td>
          <td className="ft-Wt-700">10</td>
        </tr>
      </table>
    );
  },
  jioCafe2: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>Grab &amp; Go</td>
        </tr>
        <tr className="head">
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COOKIES</td>
        </tr>
        <tr>
          <td>White chocolate &amp; cashew cookies </td>
        </tr>
        <tr>
          <td>(Contains Wheat, Nut, Milk, Soya)</td>
        </tr>
        <tr>
          <td>Chocolate chip cookies </td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk, Soya)</td>
          <td></td>
        </tr>
        <tr>
          <td>Any 2 Pieces</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Any 1 Piece</td>
          <td className="ft-Wt-700">40</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SAVORIES</td>
        </tr>
        <tr>
          <td>Cheese and Chutney Sandwich (Jain)</td>
        </tr>
        <tr>
          <td>(Conatins Wheat, Milk)</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Corn and Jalapeno Sandwich</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Choice of Grilled / Toast Sandwish</td>
          <td></td>
        </tr>
        <tr>
          <td>Samosa (2 Nos.) (Jain)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Masala Dal Kachori (2 Nos.)(Jain)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>Readymade Masala Tea</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Readymade Coffee</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Espresso</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Café Latte</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Cappuccino</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Americano</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>Anando Flavoured Milk (180 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>Anando Cold Coffee (200 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>Areated Beverage (330 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (200 ML X 24 nos.)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (500 ML)</td>
          <td className="ft-Wt-700">30</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (1000 ML)</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>New addition </td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>Prepackaged Treats </td>
        </tr>
        <tr>
          <td>Crisp wafer potatoes (35 Gms Lays) </td>
          <td className="ft-Wt-700">10</td>
        </tr>
      </table>
    );
  },
  jioCafe3: () => {
    return (
      <table>
        <tr className="title">
          <td colSpan={3}>COUNTER 1 - (GRAB &amp; GO)</td>
        </tr>
        <tr className="head">
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>SANDWICHES</td>
        </tr>
        <tr>
          <td>Cheese and Chutney Sandwich (Jain)</td>
        </tr>
        <tr>
          <td>(Conatins Wheat, Milk)</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Corn and Jalapeno Sandwich</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Choice of Grilled / Toast Sandwish</td>
          <td></td>
        </tr>
        <tr>
          <td>Samosa (2 Nos.) (Jain)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Masala Dal Kachori (2 Nos.)(Jain)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>HOT BEVERAGES</td>
        </tr>
        <tr>
          <td>Readymade Masala Tea</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Readymade Coffee</td>
          <td className="ft-Wt-700">70</td>
        </tr>
        <tr>
          <td>Espresso</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Café Latte</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Cappuccino</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Americano</td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>COLD BEVERAGES</td>
        </tr>
        <tr>
          <td>Anando Flavoured Milk (180 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>Anando Cold Coffee (200 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>Areated Beverage (330 ML)</td>
          <td className="ft-Wt-700">ON MRP</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (200 ML)X 24 nos.</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (500 ML)</td>
          <td className="ft-Wt-700">30</td>
        </tr>
        <tr>
          <td>AVA Mineral Water (1000 ML)</td>
          <td className="ft-Wt-700">50</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>COUNTER 2 - (CHAAT)</td>
        </tr>
        <tr className="head">
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr>
          <td>Sev Puri</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Samosa Chaat</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Ragda Pattice</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Papdi Chaat</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td>Mumbai Geeli Bhel</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Nut)</td>
          <td className="ft-Wt-700">100</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>COUNTER 3 - (MUMBAI STREET)</td>
        </tr>
        <tr className="head">
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr>
          <td>Misal Pav</td>
        </tr>
        <tr>
          <td></td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Pav Bhaji</td>
        </tr>
        <tr>
          <td></td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Martaban Ke Chole Kulche</td>
        </tr>
        <tr>
          <td></td>
          <td className="ft-Wt-700">220</td>
        </tr>
        <tr>
          <td>Choice of Dosa with Chutney</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Idli with Chutney</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Veg Poha</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>COUNTER 4 - (WHOLESOME MEALS)</td>
        </tr>
        <tr className="head">
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr>
          <td>Indian Set Meal</td>
        </tr>
        <tr>
          <td>(Paneer, Dal, Vegetable, Laccha Paratha, Pulao &amp; Sweet)</td>
          <td className="ft-Wt-700">400</td>
        </tr>
        <tr>
          <td>Asian Set Meal</td>
        </tr>
        <tr>
          <td>(Paneer, Vegetable, Fried Rice, Salad &amp; Dessert)</td>
          <td className="ft-Wt-700">400</td>
        </tr>
        <tr>
          <td>Veg Fried Rice Combo</td>
        </tr>
        <tr>
          <td>Fried Rice + Gravy</td>
          <td></td>
        </tr>
        <tr>
          <td>Veg Noodle Combo</td>
        </tr>
        <tr>
          <td>Noodles + Gravy</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr className="title">
          <td colSpan={3}>COUNTER 5 - (PASTA &amp; PIZZA)</td>
        </tr>
        <tr className="head">
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr>
          <td>PASTA</td>
          <td></td>
        </tr>
        <tr>
          <td>Penne in a choice of sauces</td>
        </tr>
        <tr>
          <td>(Arrabiatta” style mascarpone &amp; tomato crème,</td>
        </tr>
        <tr>
          <td>Soused garden vegetables )</td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr>
          <td>Alfredo in Grana Padano cream, Walnut Pesto</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk, Nut)</td>
          <td className="ft-Wt-700">300</td>
        </tr>
        <tr>
          <td>PIZZA</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Margarita</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Olive, Sundried Tomato and Arugla</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk, Nut)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>COUNTER 6 - (BURGERS)</td>
          <td></td>
        </tr>
        <tr>
          <td>Items</td>
          <td>Rate</td>
        </tr>
        <tr>
          <td>Vegetable Burger</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td className="ft-Wt-700">250</td>
        </tr>
        <tr>
          <td>Samosa Pav (2 Nos.)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td></td>
        </tr>
        <tr>
          <td>Vada Pav (2 Nos.)</td>
        </tr>
        <tr>
          <td>(Contains Wheat, Milk)</td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr className="subHead">
          <td colSpan={3}>NEW ADDITION</td>
        </tr>
        <tr className="title">
          <td colSpan={3}>
            COUNTER 7 - <br />
            UTTAPAM DOSAI STAGE <br />
            (TIME: 11 AM – 6 PM)
            <br />
            (Jain)
          </td>
        </tr>
        <tr>
          <td>Butter sada dosa </td>
          <td className="ft-Wt-700">150</td>
        </tr>
        <tr>
          <td>Butter masala dosa </td>
          <td className="ft-Wt-700">200</td>
        </tr>
        <tr>
          <td>Onion Gunpowder uttapam </td>
          <td className="ft-Wt-700">180</td>
        </tr>
        <tr>
          <td>Mysore masala dosa</td>
          <td className="ft-Wt-700">250</td>
        </tr>
      </table>
    );
  },
};
